const InvoiceAutographFooter = () => {
  return (
    <>
      <div className="autograph-container">
        <div>
          <div className="autograph-line"></div>
          <div className="autograph-title">Tanda Terima</div>
        </div>

        <div>
          <div className="autograph-line"></div>
          <div className="autograph-title">Hormat Kami</div>
        </div>
      </div>
    </>
  );
};

export default InvoiceAutographFooter;
