import React from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import { BiSearch } from 'react-icons/bi'
import { useAuth } from '../Hooks/authProvider';
import '../CSS/Header.css'

const Header = () => {
  const searchInputRef = React.useRef();
  const { isLoggedIn, profile } = useAuth();

  const searchBarFocus = () => {
    setTimeout(() => { searchInputRef.current.focus() }, 1000);
  }

  const ProfileSection = () => {
    return (
      <Link to="/profile">
        <div 
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: '32px',
            height: '32px',
            backgroundColor: '#FFB800',
            color: '#FFFFFF',
            fontSize: '1rem',
            fontWeight: 'bold',
            margin: '7px'
          }}
        >
          {profile?.name ? profile.name.charAt(0).toUpperCase() : 'U'}
        </div>
      </Link>
    );
  };

  return (
    <>
      <Navbar className="header fontBold d-print-none" expand="lg" variant="light">
        <div className='d-flex'>
          { isLoggedIn ? (
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            ): null 
          }

          <Navbar.Brand className="mainbrand" as={Link} to={"/"} style={{
            alignItems: "center",
            justifyContent: "center",
          }}>
            <h3>Superjaya</h3>
          </Navbar.Brand>
        </div>

        {/* Mobile View for navigation*/}
        {isLoggedIn ? (
          <div className="d-flex d-lg-none">
            <Navbar.Toggle className='d-flex align-items-center p-0' onClick={searchBarFocus}>
              <BiSearch className='nav-icon' />
            </Navbar.Toggle>

            <Link to="./cart">
              <img
                style={{
                  margin: "8px",
                  alignItem: "center"
                }}
                src="/Images/cart.jpg" alt="Cart Logo" width="32" />
            </Link>
            <ProfileSection />
          </div>
        ) : null}
        
        {/* Desktop View for navigation*/}
        {isLoggedIn ? (
          <Navbar.Collapse className="Headerlist justify-content-end fontReg">
            <hr />
            <Nav className='d-flex align-items-center'>
              <Nav.Link as={Link} to={"/c"} className="px-2">Produk</Nav.Link>
              <Nav.Item className='pl-2'>
                <SearchBar placeholder="Cari..." className='d-none d-md-block' inputRef={searchInputRef} />
              </Nav.Item>
              <Link to="./cart" className='d-none d-md-block px-2'>
                <img
                  style={{
                    height: "30px",
                    width: "32px",
                    objectFit: "contain"
                  }}
                  src="/Images/cart.jpg" 
                  alt="Cart Logo"
                />
              </Link>
              <div className="d-none d-lg-block pe-3">
                <ProfileSection />
              </div>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Nav className='ms-auto pe-3'>
            <Link to="/login">
              <img
                style={{
                  height: "30px",
                  margin: "7px",
                  alignItems: "center",
                }}
                src="/Images/user.png"
                alt="user_log"
                width="32"
              />
            </Link>
          </Nav>
        )}
      </Navbar>

      <Button className="wa-button fontBold d-print-none">
        <a href="https://wa.me/message/4IW4UAXXQ3H6D1"><img src="/Images/wa-logo.png" alt="help_button" /></a>
      </Button>
    </>
  )
}

export default Header