import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import ProductList from './ProductList'
import Categorized from './Categorized'
import ProductDetail from './ProductDetail'
import Home from './Home'
import Cart from './Cart'
import Form from './Form'
import React from 'react'
import Invoice from './Invoice'
import Help from './Help'
import InvoiceThermal from './InvoiceThermal'
import InvoiceRefund from './Refund/InvoiceRefund.js'
import LoginPage from './LoginPage'
import ProfilePage from './ProfilePage'
import { useAuth } from '../Hooks/authProvider'

// Allow Web-app to navigate (single app page) //
const Navigation = () => {
  const { isLoggedIn } = useAuth();

  const ProtectedRoute = ({ element }) => {
    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }
    return element;
  };

  return (
    <>
      <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="c" element={<ProtectedRoute element={<Categorized/>}/>}/>
          <Route path="c/:categorySlug" element={<ProtectedRoute element={<ProductList/>}/>}/>
          <Route path="p/:productId/:productSlug" element={<ProtectedRoute element={<ProductDetail/>} />}/>
          <Route path="cart" element={<ProtectedRoute element={<Cart/>} />}/>
          <Route path="cart/form" element={<ProtectedRoute element={<Form/>} />}/>
          <Route path="invoice/:orderUUID" element={<ProtectedRoute element={<Invoice/>} />}/>
          <Route path="invoice/:orderUUID/t" element={<ProtectedRoute element={<InvoiceThermal/>} />}/>
          <Route path="invoice_rtpb/:uuid" element={<ProtectedRoute element={<InvoiceRefund/>} />}/>
          <Route path="help" element={<Help/>}/>
          <Route path="login" element={<LoginPage/>}/>
          <Route path="profile" element={<ProtectedRoute element={<ProfilePage/>} />}/>
      </Routes>

      <Outlet/>
    </>
  )
}

export default Navigation