const RefundInvoiceHeader = ({ refundData }) => {
  const dateCreated = new Date(refundData.datetime_created)
  const formattedDate = `${dateCreated.getDate()}-${dateCreated.getMonth() + 1}-${dateCreated.getFullYear()}`
  return <>
    <div className="row invoice-header">
      <div className="col-6">
        <div className="mainbrand navbar-brand fontBold">
          <h1 className="ms-0">SJ SAMARINDA</h1>
        </div>
        <p className="small-text">Faktur Retur</p>
      </div>

      <div className="col-6 mt-4" style={{ paddingLeft: "100px" }}>
        <address className="small-text">
          <span className="label text-start">Tanggal</span> :{" "}
          {formattedDate} <br />
          <span className="label text-start">Nomor nota</span> :{" "}
          {refundData.id} <br />
          <span className="label text-start">Vendor</span> :{" "}
          {refundData.vendor_name} <br />
        </address>
      </div>
    </div>
    <hr style={{ margin: "8px 0px 8px 0px", height: "3px" }} />
  </>
}

export default RefundInvoiceHeader