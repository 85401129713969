import React from 'react'
import axios from 'axios'
import Slider from "react-slick";
import ProductCard from './ProductCard'
import { useEffect, useState } from 'react'
import { Container, Row, Col, Carousel, Image, Modal } from 'react-bootstrap'
import serverUrl from '../Utilities/ServerUrl'
import '../CSS/Home.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Home = () => {

  var settings = {
    infinite: true,
    speed: 250,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
    ]
  };
  
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [isBestSellingProductsLoaded, setIsBestSellingProducts] = useState(false);

  const [latestProducts, setLatestProducts] = useState([]);
  const [isLatestProductsLoaded, setIsLatestProductsLoaded] = useState(false);
  const [show, setShow] = useState(true);
  const handleClose= () => {
    setShow(false);
  }

  useEffect(() => {
    axios.get(`${serverUrl}/data/order/best/?format=json`).then(res => {
      setBestSellingProducts(res.data)
      setIsBestSellingProducts(true);
    })

    axios.get(`${serverUrl}/data/products/latest/?format=json`).then(productData => {
      setLatestProducts(productData.data.results)
      setIsLatestProductsLoaded(true);
    })
  }, []);

  const bestSellingComponents = []
  if (isBestSellingProductsLoaded) {
    let key = 1;
    for (let product of bestSellingProducts) {
      bestSellingComponents.push((
        <div key={key} className="p-2 h-100">
          <ProductCard product={product}></ProductCard>
        </div>
      ));

      key += 1;
    }
  }

  const latestProductComponent = []
  if(isLatestProductsLoaded){
    let key = 1;
    for (let product of latestProducts) {
      latestProductComponent.push((
        <div key={key} className="p-2 h-100">
          <ProductCard product={product}></ProductCard>
        </div>
      ));
  
      key += 1;
    }
  }

  return (
    <>
      {/* Show pop up when first load the home page */}
      <Modal show= {show} onHide = {handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pemberitahuan!!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Harga di website ini khusus untuk pembelian partai/grosir.</p>
        </Modal.Body>

      </Modal>
     

      <Carousel variant="dark" className="homeCarousel text-center" style={{backgroundColor: "#E8E8E8"}}>
        <Carousel.Item className="carouselFirst" >
        <img
            className="d-block w-100"
            src="../Images/Slide1.png"
            alt="Second slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="../Images/Slide2.png"
            alt="Second slide"
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src="../Images/slide3.png"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>

      <Container className='mt-5 mb-2'>
        <h2 className='fontBold text-center'>Produk Terlaris</h2>
        <Slider {...settings} className="mx-auto sliderContainer">
          {bestSellingComponents}
          {bestSellingComponents}
        </Slider>
      </Container>

      <Container fluid className="mt-5">
        <Row className="text-center">
          <Col lg={4} md={12} xs={12} className=' p-4'>
            <Image src="./Images/photo1.png" className='responsive-image' />
          </Col>

          <Col lg={4} className="d-none d-lg-block p-4">
            <Image src="./Images/photo2.png" className='responsive-image' />
          </Col>

          <Col lg={4} className="d-none d-lg-block p-4">
            <Image src="./Images/photo3.png" className='responsive-image' />
          </Col>
        </Row>
      </Container>

      
      <Container className='mt-5 mb-2'>
        <h2 className='fontBold text-center'>Produk Terbaru</h2>
        <Slider {...settings} className="mx-auto sliderContainer">
          {latestProductComponent}
          {latestProductComponent}
        </Slider>
      </Container>

      <h2 className="text-center addressHeader pt-5 fontBold">Alamat dan Kontak</h2>
      <Container fluid className="locationDiv pb-5">
        <Row>
          <Col lg={6} md={7} sm={12} xs={12} className="mapsContainer text-center mx">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.664137137899!2d117.14747761457198!3d-0.5037104996324903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2df67f9f876f1def%3A0xe3816baeebb442fc!2sSuper%20Jaya!5e0!3m2!1sid!2sid!4v1645093265004!5m2!1sid!2sid"
              style={{ border: '0' }}
              title="Superjaya_location"
              allowFullScreen=""
              loading="lazy">
            </iframe>
          </Col>

          <Col lg={6} md={5} sm={12} xs={12}>
            <h4 className='fontBold text-xs-center'>
              Jl. Niaga Selatan No.65 <br />
              Pelabuhan. Kec. Samarinda Kota. <br />
              Kota Samarinda, Kalimantan Timur 75112
            </h4>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home