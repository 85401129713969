import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Carousel, Image, Modal } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import { formatter } from '../Utilities/Currency'
import { Accordion, Spinner, } from 'react-bootstrap'
import { HiMinus, HiPlus } from 'react-icons/hi'
import '../CSS/ProductDetail.css'
import serverUrl from '../Utilities/ServerUrl'

const ProductDetail = () => {
  const { productId, productSlug } = useParams();
  const { addItem, totalUniqueItems, items } = useCart();
  const [product, setProduct] = useState();
  const [variants, setVariants] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [productCount, setproductCount] = useState(0);
  const noPictureImg = '/Images/no_image.png';
  const [notFound, setNotFound] = useState(false)

  const fetchVariant = (collectionId, collectionSlug) => {
    setIsLoading(true);

    axios.get(`${serverUrl}/data/collection/${collectionId}/${collectionSlug}`)
      .then(res => {
        const productVariants = res.data.results.map(v => v.product)
        setVariants(productVariants)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    axios.get(`${serverUrl}/data/product/${productId}/${productSlug}`).then(res => {
      setProduct(res.data);

      if (res.data.first_collection_id && res.data.first_collection_slug)
        fetchVariant(res.data.first_collection_id, res.data.first_collection_slug)
    })
    .catch(error => {
      error = error.toJSON()
      if (error.status === 404) {
        setNotFound(true)
      }
    })
    .finally(() => {
      setIsLoading(false);
    })
    setproductCount(1)
  }, [productId, productSlug]);

  if (notFound) {
    return (
      <div className='h-100 d-flex align-items-center justify-content-center'>
        <h3>Item not found.</h3>
        <p>404</p>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Spinner className="spinner" animation="border" role="status" size="xl">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  function ButtonClicked(){
    const existingProduct = items.find((p) => p.id === product.id);

    if (existingProduct && existingProduct.quantity + productCount > product.stock) {
      setIsDialogVisible(true);
      setDialogType('exceedProductStock');
    } 
    else if(totalUniqueItems + 1 > 100){
      setIsDialogVisible(true);
      setDialogType('exceedTotalUniqueItem');
    }
    else {
      // Otherwise, add the product to the cart
      addItem(product, productCount);
      setShow(true);
    }
  }

  return (
    <>
      <Modal show={isDialogVisible && dialogType ==='exceedProductStock'} onHide={() => setIsDialogVisible(false)} animation={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header>
          <Modal.Title className="fontBold">Tidak dapat menambahkan</Modal.Title>
        </Modal.Header>
        <Modal.Body>Barang anda {product.name} tidak dapat ditambahkan karena stok barang ini tersisa {product.stock} dan dikeranjang anda telah tersedia {product.stock}</Modal.Body>
        <Modal.Footer>
          <Button className="cartAddedButton" onClick={() => setIsDialogVisible(false)}>
            Tutup
          </Button>
        </Modal.Footer>

      </Modal>

      <Modal show={isDialogVisible && dialogType ==='exceedTotalUniqueItem'} onHide={() => setIsDialogVisible(false)} animation={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header>
          <Modal.Title className="fontBold">Tidak dapat menambahkan</Modal.Title>
        </Modal.Header>
        <Modal.Body>Total barang yang berada di keranjang Anda telah lebih dari 100 jenis barang, silahkan checkout terlebih dahulu</Modal.Body>
        <Modal.Footer>
          <Button className="cartAddedButton" onClick={() => setIsDialogVisible(false)}>
            Tutup
          </Button>
        </Modal.Footer>

      </Modal>

      <Modal show={show} onHide={() => setShow(false)} animation={false} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header>
          <Modal.Title className="fontBold">Berhasil ditambahkan</Modal.Title>
        </Modal.Header>
        <Modal.Body>Barang anda {product.name} telah ditambahkan ke keranjang</Modal.Body>
        <Modal.Footer>
          <Button className="cartAddedButton" onClick={() => setShow(false)}>
            Tutup
          </Button>
        </Modal.Footer>

      </Modal>

      <Container fluid>
          <Row className="productdisplay" key={product.id}>
            <Col lg={6} xs={12} className="text-center">
              <Carousel variant="dark">
                <Carousel.Item interval={2500}>
                  <Image fluid className='detailedImage'
                    src={product.images[0]?.image ? product.images[0]?.image : noPictureImg}
                    alt="First slide"
                  />
                </Carousel.Item>

                {product.images[1]?.image && (
                  <Carousel.Item interval={2500}>
                    <Image fluid className='detailedImage'
                      src={product.images[1]?.image}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                )}

                {product.images[2]?.image && ( // Check if the third image exists
                  <Carousel.Item interval={2500}>
                    <Image fluid className='detailedImage'
                      src={product.images[2]?.image}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                )}
              </Carousel>
            </Col>

            <Col lg={6} xs={12}
              style={{
                paddingLeft: "10px"
              }}
              className="productbranding fontRegular"
            >
              <h5> { product.category ? product.category.name : '' } - {product.brand ? product.brand.name : ''} </h5>

              <h1 className="fontBold">{product.name}</h1>

              <p>{product.short_description}</p>

              <h2 className="fontBold">{formatter.format(product.price)}</h2>

              <h6>No Produk/SKU : {product.sku}</h6>

              {product.stock === 0 ?
                <p className="productempty">Barang Sedang Kosong</p> :
                <p className="productavailable fontMedium">Barang tersedia, Stok tersisa: {product.stock}</p>
              }

              
              <Row className='updateQuantityContainer'>
                  <Col>
                      <Button onClick={() => {
                        if(productCount > 0){
                          setproductCount(productCount -1)
                        }
                      }}
                      disabled={productCount === 1} 
                      style={{backgroundColor: "transparent", color: "black"}}>
                          <HiMinus style={{
                              fontSize:"15px",
                              cursor:"pointer",
                          }}/>
                      </Button>
                  </Col>
                  <Col>
                  <input 
                    type="number" 
                    className='input-quantity' 
                    value={productCount}
                    onChange={(e) => {
                      setproductCount(parseInt(e.target.value))
                    }}
                    onBlur= {(e) => {
                      const enteredValue = parseInt(e.target.value);
                      if (isNaN(enteredValue) || enteredValue < 1) {
                        setproductCount(1);
                      } else {
                        setproductCount(Math.min(enteredValue, product.stock));
                      }
                    }}
                  />
                  </Col>
                  <Col>
                      <Button 
                        onClick={() => setproductCount(productCount + 1)} 
                        disabled={productCount === product.stock}
                        style={{backgroundColor: "transparent", color: "black"}}
                      >
                          <HiPlus style={{
                              fontSize:"15px",
                              cursor:"pointer",
                          }} />
                      </Button>
                  </Col>
              </Row>

              <Button className="mt-2 addtocart fontBold" disabled={product.stock === 0} onClick={() => ButtonClicked()}>
                <img src="/Images/cart.jpg" alt="Cart_logo" />
                Tambah ke Keranjang
              </Button>

              <div className='row w-100 mt-2'>
                {variants && variants.map(variant => 
                  <div className='col-6 ps-0 pe-2 pb-2'>
                    <Link to={`/p/${variant.id}/${variant.slug}`}>
                      <div className={variant.id === product.id ? 'card h-100 p-3 flex-row align-items-center border-success' : 'card h-100 p-3 flex-row align-items-center'}>
                        <img src={variant.images.length > 0 ? variant.images[0].image : "#"} alt={variant.name} width={32} height={32} style={{objectFit: "contain"}}/>
                        <div className='ms-2 ellipsis-1 fs-xs'>{variant.name}</div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          </Row>
      </Container>

      <Container fluid className="mt-4">
        <Row>
          <Col lg={12} xs={12} className=" fontRegular">
            <Accordion defaultActiveKey="0" >
              <Accordion.Item eventKey="0">
                  <Accordion.Header className="fontMedium">Deskripsi Produk</Accordion.Header>
                  <Accordion.Body>
                    <pre className="fontRegular">
                      {product.description}
                    </pre>
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductDetail