export function paginate(array, firstPageItems = 12, subsequentPageItems = 16) {
  const paginatedArray = [];

  // Handle the first page
  const firstPage = array.slice(0, firstPageItems);
  paginatedArray.push(firstPage);

  // Handle subsequent pages
  for (let i = firstPageItems; i < array.length; i += subsequentPageItems) {
    const page = array.slice(i, i + subsequentPageItems);
    paginatedArray.push(page);
  }

  return paginatedArray;
}
