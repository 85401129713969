import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../Hooks/authProvider';
import loginIllustration from '../assets/login-illustration.svg';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { handleLogin, error: authError, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profile');
    }
  }, [isLoggedIn, navigate]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!identifier) {
      newErrors.identifier = 'Username atau Email harus diisi';
    }
    
    if (!password) {
      newErrors.password = 'Password harus diisi';
    } else if (password.length < 6) {
      newErrors.password = 'Password minimal 6 karakter';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      handleLogin(identifier, password);
    }
  };

  return (
    <Container fluid className="vh-100">
      <Row className="h-100">
        {/* Left Column - Illustration */}
        <Col md={6} className="d-flex align-items-center justify-content-center" style={{
          backgroundColor: '#f8f9fa'
        }}>
          <div className="text-center">
            <img 
              src={loginIllustration} 
              alt="Login Illustration" 
              style={{ maxWidth: '80%', height: 'auto' }}
            />
            <h2 className="mt-4 fontBold">Selamat Datang Kembali!</h2>
            <p className="fontRegular">Silakan masuk untuk melanjutkan ke akun Anda</p>
          </div>
        </Col>

        {/* Right Column - Login Form */}
        <Col md={6} className="d-flex align-items-center justify-content-center">
          <div style={{ width: '80%', maxWidth: '400px' }}>
            <h1 className="mb-4 fontBold">Masuk</h1>
            
            {authError && (
              <Alert 
                variant="danger" 
                dismissible
              >
                Username/Email atau Password salah
              </Alert>
            )}

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="fontMedium">Username atau Email</Form.Label>
                <Form.Control
                  type="text"
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                  isInvalid={!!errors.identifier}
                  className="fontRegular"
                  placeholder="Masukkan username atau email Anda"
                />
                <Form.Control.Feedback type="invalid" className="fontRegular">
                  {errors.identifier}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label className="fontMedium">Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={!!errors.password}
                  className="fontRegular"
                  placeholder="Masukkan password Anda"
                />
                <Form.Control.Feedback type="invalid" className="fontRegular">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Button 
                variant="primary" 
                type="submit" 
                className="w-100 fontMedium"
                style={{
                  padding: '0.8rem',
                  fontSize: '1.1rem',
                  backgroundColor: '#1C4765',
                  border: 'none'
                }}
              >
                Masuk
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;