import React from 'react'
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import '../CSS/Footer.css'

const Footer = () => {
  return (
    <>
      <Container fluid className="footerdiv fontMedium d-print-none">
        <Row className="align-items-center">
          <Col lg={4} md={4} sm={4} xs={4} className="footer-left">
            <p>
              info@superjaya.id
            </p>
          </Col>

          <Col lg={4} md={4} sm={4} xs={4} className="text-center footer-mid">
            <p>© 2023 Super Jaya</p>
          </Col>

          <Col lg={4} md={4} sm={4} xs={4} className="text-end footer-right">
            <a href="https://www.google.com/">
              <img src="/Images/ig.jpg" alt="Whatsapp's logo" width="40"/>
            </a>
            
            <a href="https://wa.me/message/4IW4UAXXQ3H6D1">
              <img style={{
                marginRight:"35px",
              }} 
              src="/Images/wa-logo2.png" alt="Instagram's logo" width="20"/>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Footer