import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import Paginations from './MultiPagePagination'
import serverUrl from '../Utilities/ServerUrl'

const Categorized = () => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const noPictureImg = '/Images/no_image.png';
  const [currentPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1)

  useEffect(() => {
    axios.get(`${serverUrl}/data/categories/?format=json&page=${currentPage ? currentPage : 1}`).then(res => {
      setCategories(res.data.results);
      setData(res.data);
      setIsLoading(false);
    })
  }, [currentPage]);

  if (isLoading) {
    return (
      <Spinner className="spinner" animation="border" role="status" size="xl">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <>
      <h2 className='text-center mt-3 fontBold'>Produk</h2>
      <Row className="mt-3">
        {categories && categories.map(category => {
          const { id, name, image, slug } = category;
          return (
            <Col lg={3} md={4} xs={6} key={id}>
              <Link className='card-product' to={`/c/${slug}`}>
                <Card className="categorylist flex-fill">
                  <img className="mx-auto mt-4" src={image ? image : noPictureImg} alt={name} />
                  <Card.Body className="text-center fontBold">
                    <Card.Title>{name}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          )
        })}

        <Paginations
          pageURL={`/c`}
          data={data}
          currentPage={currentPage}
        ></Paginations>
      </Row>
    </>
  )
}

export default Categorized