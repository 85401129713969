import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useCart } from 'react-use-cart'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import '../CSS/Form.css'
import serverUrl from '../Utilities/ServerUrl'
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from '../Hooks/authProvider'

const Form = () => {
  const [submitting, setSubmitting] = useState(false)
  const [customers, setCustomers] = useState([])
  const { items, emptyCart } = useCart();
  const navigate = useNavigate();
  const auth = useAuth();
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {

    if (!auth.profile.isStaff) {
      return
    }

    const fetchCustomers = async () => {
      const resp = await axios.get(`${serverUrl}/data/customers/`, {
        headers: {
          Authorization: auth.authHeader,
        }
      })

      setCustomers(resp.data)
    }

    fetchCustomers()

  }, [auth])

  function returnHome(response) {
    navigate(`/invoice/${response.data.order_uuid}`, { approved: true });
  }

  const url = `${serverUrl}/data/order/`;
  const [data, setData] = useState({
    fullname: auth.profile.isStaff ? "" : auth.profile.name,
    phone_number: auth.profile.isStaff ? "" : auth.profile.phone,
    email: "",
    address: auth.profile.isStaff ? "" : auth.profile.address,
    npwp: auth.profile.isStaff ? "" : auth.profile.npwp,
    note: "",
    payment_method: "m-banking",
    customer_id: auth.profile.isStaff ? "" : auth.profile.id,
  })

  const validateForm = () => {
    const errors = {};
    if (!data.fullname?.trim()) {
      errors.fullname = "Nama Pembeli wajib diisi";
    }
    if (!data.payment_method) {
      errors.payment_method = "Metode Pembayaran wajib dipilih";
    }
    if (data.email && !/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Format email tidak valid";
    }
    if (data.phone_number && !/^\d{10,13}$/.test(data.phone_number)) {
      errors.phone_number = "Nomor telepon harus 10-13 digit";
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  function submit(e,) {
    e.preventDefault();

    const itemIndex = [];
    for (var i = 0; i < items.length; i++) {
      itemIndex.push({
        product_id: items[i].id,
        pcs: items[i].quantity,
      })
    }

    axios.post(url, {
      fullname: data.fullname,
      phone_number: data.phone_number,
      email: data.email,
      note: data.note,
      items: itemIndex.map(item => {
        return {
          product_id: item.product_id,
          pcs: item.pcs,
        }
      }),
      payment_method: data.payment_method,
      customer: data.customer_id,
    }, {
      headers: {
        Authorization: auth.authHeader,
      }
    })
      .then(response => {
        if (response.statusText === 'Created') {
          emptyCart()
          returnHome(response)
        } else {
          alert("Failed to Submit Order, Please try again")
        }
      })
      .catch(err =>
        console.error(err.toJSON())
      )
      .finally(() => {
        setSubmitting(false)
      })
  }

  function handleOnChange(e) {
    const newData = { ...data }
    newData[e.target.id] = e.target.value
    setData(newData)
  }

  const handleSubmit = (e) => {
    setSubmitting(true)
    if (validateForm()) {
      submit(e);
      return
    }
    setSubmitting(false)
  }

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  const selectCustomer = (customer) => {
    setData({
      fullname: customer.name,
      phone_number: customer.phone,
      address: customer.address,
      npwp: customer.npwp,
      customer_id: customer.id,

      email: data.email,
      note: data.note,
      payment_method: data.payment_method,
    });
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const filtered = customers.filter(customer =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (customer.phone && customer.phone.includes(searchQuery))
    );
    setFilteredCustomers(filtered);
  }, [searchQuery, customers]);

  return (
    <Container fluid className="px-2">
      <Row className="justify-content-center">
        <Col xs={12} className="text-center">
          <h2 className="formGuide">Silakan lengkapi formulir pemesanan</h2>
        </Col>
      </Row>

      <Container className="formcontainer">
        <Row className="formheader">
          <Col xs={12}>
            <h2>Form Pemesanan</h2>
          </Col>
        </Row>

        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}>
          <Row>
            <Col xs={12} className="formcol">
              {auth.profile.isStaff && (
                <div className="form-group">
                  <label htmlFor="customer">Customer</label>
                  <div className="custom-select" ref={dropdownRef}>
                    <div 
                      className="select-header" 
                      onClick={() => {
                        console.log('Current fullname:', data.fullname);
                        setIsDropdownOpen(!isDropdownOpen);
                      }}
                    >
                      {(data.fullname === undefined || data.fullname === null || data.fullname === "") 
                        ? "-- Pilih Customer --"
                        : `${data.fullname}${data.phone_number ? ` - ${data.phone_number}` : ''}`
                      }
                    </div>

                    {/*Handle Dropdown to select customer*/}
                    {isDropdownOpen && (
                      <div className="select-dropdown">
                        <div className="search-container">
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Cari..."
                            onClick={(e) => e.stopPropagation()}
                            autoFocus
                          />
                        </div>
                        <div className="options-container">
                          {filteredCustomers.length > 0 ? (
                            filteredCustomers.map((customer) => (
                              <div 
                                key={customer.id}
                                className="option"
                                onClick={() => selectCustomer(customer)}
                              >
                                <div className="option-name">{customer.name}</div>
                              </div>
                            ))
                          ) : (
                            <div className="no-options">
                              Tidak ada customer yang ditemukan
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <Row className='form-row'>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="fullname">Nama Pembeli</label>
                    <input
                      type="text"
                      id="fullname"
                      value={data.fullname}
                      onChange={handleOnChange}
                      className={formErrors.fullname ? 'input-error' : ''}
                      placeholder="Tulis Nama Pembeli"
                      required
                    />
                    {formErrors.fullname && (
                      <div className="form-error">{formErrors.fullname}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="phone_number">Nomor Telepon</label>
                    <input
                      type="tel"
                      id="phone_number"
                      value={data.phone_number}
                      onChange={handleOnChange}
                      className={formErrors.phone_number ? 'input-error' : ''}
                      placeholder="Tulis Nomor Telepon"
                    />
                    {formErrors.phone_number && (
                      <div className="form-error">{formErrors.phone_number}</div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className='form-row'>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={data.email}
                      onChange={handleOnChange}
                      className={formErrors.email ? 'input-error' : ''}
                      placeholder="email@example.com"
                    />
                    {formErrors.email && (
                      <div className="form-error">{formErrors.email}</div>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label htmlFor="payment_method">Metode Pembayaran</label>
                    <select
                      id="payment_method"
                      value={data.payment_method}
                      onChange={handleOnChange}
                      className={formErrors.payment_method ? 'input-error' : ''}
                      required
                    >
                      <option value="">Pilih metode</option>
                      <option value="Tunai">Tunai</option>
                      <option value="m-banking">Transfer (M-Banking)</option>
                      <option value="Debit-kredit">Debit / Kredit</option>
                    </select>
                    {formErrors.payment_method && (
                      <div className="form-error">{formErrors.payment_method}</div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="form-group">
                <label htmlFor="note">Catatan</label>
                <input
                  type="text"
                  id="note"
                  value={data.note}
                  onChange={handleOnChange}
                  placeholder="Tambahkan catatan jika diperlukan"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="text-end">
              <Button
                type="submit"
                disabled={submitting}
                className="invoicebutton"
              >
                {submitting ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ms-2">Memproses...</span>
                  </>
                ) : (
                  'Kirim Pesanan'
                )}
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </Container>
  );
}

export default Form