import Header from './Components/Header'
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import './CSS/App.css';

function App() {
  return (
    <div className="app-div">
      <Header/>
      <Navigation/>
      <Footer/>
    </div>
  );
}

export default App;
