import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react'
import { Card, Row, Col, Spinner } from 'react-bootstrap'
import { useParams, Link, useSearchParams } from 'react-router-dom';
import '../CSS/ProductList.css'
import Paginations from './MultiPagePagination';
import { formatter } from '../Utilities/Currency';
import serverUrl from '../Utilities/ServerUrl';

const ProductList = () => {
  const { categorySlug } = useParams();
  const [data, setData] = useState();
  const [productList, setProductList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const noPictureImg = '/Images/no_image.png';
  const [currentPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1)

  useEffect(() => {
    axios.get(`${serverUrl}/data/products/?format=json&category=${categorySlug}&page=${currentPage ? currentPage : 1}`).then(res => {
      setProductList(res.data.results);
      setData(res.data);
      setIsLoading(false);
    })
  }, [categorySlug, currentPage]);

  if (isLoading) {
    return (
      <Spinner className="spinner" animation="border" role="status" size="xl">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <>
      <Row>
        {productList && productList.map(product => {
          const { id, name, price, images, slug } = product;
          return (
            <Col lg={3} xs={6} key={id}>
              <Link className='card-product' to={`/p/${id}/${slug}`}>
                <Card className="productlist h-100">
                  <img 
                    className="mx-auto mt-4" 
                    style={{height: 256, objectFit: 'contain'}} 
                    src={images[0]?.image ? images[0]?.image : noPictureImg} alt={name}
                  />
                  <Card.Body className="text-center fontBold">
                    <Card.Title>{name}</Card.Title>
                    <Card.Text className="fontMedium">
                      {formatter.format(price)}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          )
        })}

        <Paginations
          pageURL={`/c/${categorySlug}`}
          data={data}
          currentPage={currentPage}
        ></Paginations>

      </Row>
    </>
  )
}

export default ProductList