import { formatter } from "../Utilities/Currency";
import { useState } from 'react'

const InvoiceNote = ({ total, orderData }) => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('bca');

  const changePaymentOption = (event) => {
    setSelectedPaymentOption(event.target.value);
  };

  let paymentData;

  if (selectedPaymentOption === "bca") {
    paymentData = (
      <p>
        BCA <b>0271611179</b> a/n Halito
      </p>
    );
  } else if (selectedPaymentOption === "bri") {
    paymentData = (
      <p>
        BRI <b>008201001677565</b> a/n Yuliana
      </p>
    );
  } else if (selectedPaymentOption === "tunai") {
    paymentData = (
      <p>
        Pembayaran tunai dapat dilakukan dengan mengunjungi toko di alamat{" "}
        <br /> <b>Jl. Niaga Selatan No.65 Pelabuhan. Kec. Samarinda Kota</b>.
        <br />
        Harap menyimpan nomor Invoice yang tertera. (Invoice nomor #
        {orderData.id})
      </p>
    );
  } else {
    paymentData = (
      <p>
        Pembayaran utang dapat dilakukan dengan mengunjungi toko di alamat{" "}
        <br /> <b>Jl. Niaga Selatan No.65 Pelabuhan. Kec. Samarinda Kota</b>.
        <br />
        Harap menyimpan nomor Invoice yang tertera. (Invoice nomor #
        {orderData.id})
      </p>
    );
  }

  return (
    <>
      <div className="invoice-container d-print-none">
        <div className="row text-muted">
          <small>
            <h4 className="mb-0">Keterangan:</h4>
            <ol className="mb-0">
              <li>
                Harap transfer sesuai dengan harga yang tertera pada baris{" "}
                <b>Total. {formatter.format(total)}</b>
              </li>

              <li>
                Transfer dapat dilakukan ke salah satu rekening dibawah, pilih
                rekening tujuan:
                <select
                  className="form-select form-select-sm text-muted my-2"
                  onChange={changePaymentOption}
                >
                  <option value="bca">BCA</option>
                  <option value="bri">BRI</option>
                  <option value="tunai">Tunai</option>
                  <option value="utang">Utang</option>
                </select>
                {paymentData}
              </li>

              <li>
                Bukti transfer dapat di kirimkan ke salah satu akun WhatsApp
                berikut agar pesanan dapat segera di proses:
                <ul>
                  <li className="my-0">
                    WA: <p>+628135050771</p>
                  </li>
                </ul>
              </li>

              <li>
                Harap menyimpan nomor invoice yang tertera (Invoice nomor #
                {orderData.id}).
              </li>
              <li>
                Status pembayaran akan di update di halaman ini, setelah
                pembayaran di konfirmasi.
              </li>
            </ol>
          </small>
        </div>
      </div>
    </>
  );
};

export default InvoiceNote
