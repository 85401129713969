import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../CSS/Help.css'

const Help = () => {
  return (
   <>
    <Container fluid className="stepContainer">
        <Row>
            <h4 className="fontBold text-center">Ada yang bisa kami bantu ?</h4>
            <Col lg={12}>
                <ol className="fontRegular steplist">
                    <li><a href="/help#part1">Kunjungi Halaman Produk </a></li>
                    <li><a href="/help#part2">Pilih Kategori Produk yang diinginkan</a></li>
                    <li><a href="/help#part3">Pilih Produk yang diinginkan</a></li>
                    <li><a href="/help#part4">Silahkan Periksa detail Produk</a></li>
                    <li><a href="/help#part5">Tambahkan barang ke keranjang</a></li>
                    <li><a href="/help#part6">Silahkan Check-out Keranjang</a></li>
                    <li><a href="/help#part7">Silahkan Isi Data diri untuk pemesanan</a></li>
                    <li><a href="/help#part8">Silahkan Unduh atau Print Invoice</a></li>
                </ol>
            </Col>

            <Col lg={12} id="part1" className="my-2">
                <h4 className="fontBold">1. Kunjungi Halaman Produk</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part2" className="my-2">
                <h4 className="fontBold">2. Pilih Kategori Produk yang diinginkan</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part3" className="my-2">
                <h4 className="fontBold">3. Pilih Produk yang diinginkan</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part4"className="my-2">
                <h4 className="fontBold">4. Silahkan Periksa detail Produk</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part5" className="my-2">
                <h4 className="fontBold">5. Tambahkan barang ke keranjang</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part6" className="my-2">
                <h4 className="fontBold">6. Silahkan Check-out Keranjang</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part7" className="my-2">
                <h4 className="fontBold">7. Silahkan Isi Data diri untuk pemesanan</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} id="part8"className="my-2">
                <h4 className="fontBold">8. Silahkan Unduh atau Print Invoice</h4>
                <p className="fontRegular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do 
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
                </p>
                <Image fluid src="#" alt="buy_step1"/>
            </Col>

            <Col lg={12} className="text-center">
                <Link to="/c">
                    <Button className="stepButton my-3" >Mulai Belanja</Button>
                </Link>
            </Col>
        </Row>

    </Container>
   </>
  )
}

export default Help