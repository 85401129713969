import React from 'react';
import { Card, Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../Hooks/authProvider';
import { FaUser, FaPhone, FaMapMarkerAlt, FaPowerOff, FaInfoCircle } from 'react-icons/fa';


const ProfilePage = () => {
    const { handleLogout, profile } = useAuth();

    // Check if any required information is missing
    const hasMissingInfo = !profile.name || !profile.phone || !profile.address;

    const ProfileItem = ({ icon, label, value }) => (
        <div className="mb-4">
            <div className="d-flex align-items-center mb-2">
                {icon}
                <span className="ms-2 fontMedium text-muted">{label}</span>
            </div>
            <div className="fontRegular ps-4" style={{ 
                fontSize: '1.1rem',
                color: value ? 'inherit' : '#6c757d'
            }}>
                {value || 'Belum diisi'}
            </div>
        </div>
    );

    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col md={8} lg={6}>
                    {hasMissingInfo && (
                        <Alert variant="warning" className="mb-4">
                            <div className="d-flex align-items-center">
                                <FaInfoCircle size={20} className="me-2" style={{ color: '#856404' }}/>
                                <span className="fontRegular">
                                    Beberapa informasi belum lengkap. Silakan hubungi admin Superjaya untuk memperbarui profil Anda.
                                </span>
                            </div>
                        </Alert>
                    )}
                    
                    <Card className="border-0 shadow-sm">
                        <Card.Body className="p-4">
                            {/* Profile Header */}
                            <div className="text-center mb-4">
                                <div 
                                    className="rounded-circle mx-auto mb-3 d-flex align-items-center justify-content-center"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        backgroundColor: '#FFB800',
                                        color: '#FFFFFF',
                                        fontSize: '2.5rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {profile.name ? profile.name.charAt(0).toUpperCase() : 'P'}
                                </div>
                                <h2 className="fontBold mb-1">{profile.name || 'Pengguna'}</h2>
                                <p className="text-muted fontRegular">Profil Pelanggan</p>
                            </div>

                            {/* Profile Information */}
                            <div className="p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '10px' }}>
                                <ProfileItem 
                                    icon={<FaUser style={{ color: '#FFB800' }} size={20} />}
                                    label="Nama Lengkap"
                                    value={profile.name}
                                />
                                <ProfileItem 
                                    icon={<FaPhone style={{ color: '#FFB800' }} size={20} />}
                                    label="Nomor Telepon"
                                    value={profile.phone}
                                />
                                <ProfileItem 
                                    icon={<FaMapMarkerAlt style={{ color: '#FFB800' }} size={20} />}
                                    label="Alamat"
                                    value={profile.address}
                                />
                            </div>

                            {/* Action Buttons */}
                            <div className="mt-4 d-flex justify-content-center">
                                <Button 
                                    variant="danger"
                                    className="fontMedium d-flex align-items-center"
                                    onClick={handleLogout}
                                    style={{
                                        padding: '0.8rem 2rem',
                                        borderRadius: '8px',
                                        backgroundColor: '#dc3545',
                                        border: 'none'
                                    }}
                                >
                                    <FaPowerOff className="me-2" />
                                    Keluar
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ProfilePage;