import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import serverUrl from '../../Utilities/ServerUrl'
import { Spinner } from "react-bootstrap";
import { format } from "../../Utilities/Currency";
import { createInvoicePages, createTable } from "../../Services/InvoiceService";
import RefundInvoiceHeader from "./RefundInvoiceHeader";

function RefundDetailRow({ refundDetail }) {
  return <>
    <tr key={`invrow-${refundDetail.id}`}>
      <td className='checkbox-col'>
        <div className='invoice-checkbox'></div>
      </td>
      <td className='text-center'>{refundDetail.quantity}</td>
      <td className='name-col'>
        <div className="name-text">{refundDetail.product.name}</div>
      </td>
      <td className='price-col'>
        <div className='d-flex justify-content-between'>
          <div>Rp</div>
          <div>{format(refundDetail.cost)}</div>
        </div>
      </td>
      <td className='price-col text-end'>
        <div className='d-flex justify-content-between'>
          <div>Rp</div>
          <div>{format(refundDetail.quantity * refundDetail.cost)}</div>
        </div>
      </td>
    </tr>
  </>
}

function RefundTableHeader({ pageIndex }) {
  return <>
    <thead>
      <tr>
        <th className='checkbox-col'>{pageIndex}</th>
        <th className="text-center px-2">Qty</th>
        <th className='name-col'>
          <div className="name-text px-2">Nama Barang</div>
        </th>
        <th className="price-col text-center">Harga Modal</th>
        <th className="price-col text-center">Subtotal</th>
      </tr>
    </thead>
  </>
}

export function RefundTableFooter({ total }) {
  return (
    <tfoot>
      <tr>
        <th className="text-end px-2" colSpan={4}>
          Total
        </th>
        <th className="text-end px-2" colSpan={1}>
          <div className='d-flex justify-content-between'>
            <div>Rp</div>
            <div>{format(total)}</div>
          </div>
        </th>
      </tr>
    </tfoot>
  )
}

const InvoiceRefund = () => {
  const { uuid } = useParams();
  const [refundData, setRefundData] = useState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    axios.get(`${serverUrl}/data/retur/?uuid=${uuid}`)
      .then(res => {
        setRefundData(res.data)
        
        let grandTotal = 0

        for (let detail of res.data.returdetail_set) {
          const sub = detail.quantity * detail.cost
          grandTotal += sub
        }

        setTotal(grandTotal)
        setIsLoading(false)
      })
  }, [uuid])

  if (isLoading) {
    return (
      <Spinner className="spinner" animation="border" role="status" size="xl">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const invoiceHeader = <RefundInvoiceHeader refundData={refundData} />
  const pages = createInvoicePages(
    refundData.returdetail_set, 
    invoiceHeader, 
    (pageIndex, page, isLastPage) => {
      const thead = <RefundTableHeader pageIndex={pageIndex + 1} />
      const tableRows = []
      for (let returDetail of page) {
        tableRows.push(<RefundDetailRow refundDetail={returDetail} />)
      }
      const tfoot = isLastPage ? <RefundTableFooter total={total} /> : null
      return createTable(thead, tableRows, tfoot)
    }
  )

  return <>
    <div className="container px-0 invoice-actions-container d-print-none" style={{ 'maxWidth': '850px' }}>
      <button className='btn action-button mx-2 d-print-none' onClick={window.print}>Print</button>
    </div>

    {Object.keys(pages).map((pageNumber, index) => {
      return pages[pageNumber]
    })}
  </>
}

export default InvoiceRefund